<template>
	<ol v-if="slideCount" class="swiper-dots">
		<li v-for="index in slideCount" :key="index">
			<button
				class="swiper-dot"
				:class="{
					'swiper-dot--active': activeIndex === index,
				}"
				@click="slider?.slideTo(index)">
				<v-sr-only
					:value="
						activeIndex === index ? 'current slide' : `slide ${index + 1}`
					" />
			</button>
		</li>
	</ol>
</template>

<script lang="ts" setup>
	import type Slider from './slider.vue'

	const props = defineProps<{
		slider?: InstanceType<typeof Slider>
	}>()

	const activeIndex = computed(() => props.slider?.activeIndex ?? 0)

	const slideCount = computed(() => props.slider?.slides?.length ?? 0)
</script>

<style lang="scss" scoped>
	@layer components {
		.swiper-dots {
			list-style: none;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding-left: 0;
		}

		.swiper-dot {
			position: relative;
			display: block;
			width: 30px;
			height: 30px;
			color: currentColor;

			&:hover {
				&:before {
					opacity: 0.75;
					transform: translate(-50%, -50%) scale(0.75);
				}
			}

			&.swiper-dot--active {
				&:before {
					opacity: 1;
					transform: translate(-50%, -50%);
				}
			}

			&:before {
				content: '';
				position: absolute;
				inset: 50%;
				width: 10px;
				height: 10px;
				transform: translate(-50%, -50%) scale(0.6);
				border-radius: 50%;
				background-color: currentColor;
				opacity: 0.5;
				transition: 0.3s;
				transition-property: transform, background-color, opacity;
			}
		}
	}
</style>
